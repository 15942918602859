<template>
  <Toast />
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">VENTAS</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">COMISIONES</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <h4><strong>INFORME GENERAL DE COMISIONES</strong></h4>
    </div>
    <div class="col-12">
      <div class="card p-fondo">
        <div class="p-fluid formgrid grid">
          <!-- <div class="field col-12 md:col-2">
              <label for="sucursal"><strong>SUCURSAL: </strong></label>
              <Dropdown
                v-model="sucursalSelected"
                :options="sucursales"
                optionLabel="nombre"
                optionValue="id"
              >
              </Dropdown>
            </div> -->
          <div class="field col-12 md:col-4">
            <label for="usuarioSelected"><strong>COMISIONISTA: </strong></label>
            <div class="p-inputgroup">
              <Dropdown
                v-model="usuarioSelected"
                :options="usuarios"
                optionLabel="name"
                optionValue="id"
                :filter="true"
                @change="BuscarFiltro"
              >
              </Dropdown>
              <Button
                label="PAGAR"
                icon="pi pi-money-bill"
                class="p-button-primary p-button-sm"
                style="font-weight: bold"
                v-tooltip.top="'Pagar Comisión'"
                :loading="enviando"
                :disabled="
                  enviando ||
                  usuarioSelected == 0 ||
                  comisiones.find((x) => x.saldo > 0) == undefined
                "
                @click="newPagoComision"
              />
            </div>
          </div>
          <div class="field col-12 md:col-2">
            <label for="estado"><strong>ESTADOS: </strong></label>
            <Dropdown
              v-model="estadoSelected"
              :options="estados"
              optionLabel="nombre"
              optionValue="id"
            >
            </Dropdown>
          </div>
          <div class="field col-12 md:col-2">
            <label for="fecha_inicio"><strong>FECHA INCIO: </strong></label>
            <Calendar
              id="fecha_inicio"
              v-model="fecha_inicio"
              :showIcon="true"
              dateFormat="dd/mm/yy"
            />
          </div>
          <div class="field col-12 md:col-2">
            <label for="fecha_fin"><strong>FECHA FIN: </strong></label>
            <Calendar
              id="fecha_fin"
              v-model="fecha_fin"
              :showIcon="true"
              dateFormat="dd/mm/yy"
            />
          </div>
          <div class="field col-12 md:col-2">
            <label for=""><strong>ACCIONES: </strong></label>
            <Button
              label="BUSCAR"
              icon="pi pi-search"
              class="p-button-primary mr-2"
              style="font-weight: bold"
              v-tooltip.top="'Buscar'"
              :loading="enviando"
              :disabled="enviando"
              @click="BuscarFiltro"
            />
          </div>
        </div>
      </div>
      <div class="mt-4">
        <DataTable
          ref="reporte_comisiones"
          key="id"
          :value="comisiones"
          responsiveLayout="scroll"
          :loading="enviando"
          class="p-datatable-sm"
          :filters="buscar"
          responsive="true"
          stripedRows
          showGridlines
          v-model:expandedRows="expandedRows"
          :paginator="true"
          :rows="100"
          :rowHover="true"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Registros"
          :rowsPerPageOptions="[100, 200, 500]"
          selectionMode="single click"
        >
          <template #header>
            <div
              class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
            >
              <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
                <i class="pi pi-search" />
                <InputText
                  v-model="buscar['global'].value"
                  placeholder="Buscar..."
                />
              </span>
              <span
                class="block mt-2 md:mt-0 p-input-icon-left"
                v-if="'Comisiones Listar' in auth.user.permissions"
              >
                <Button
                  text
                  icon="pi pi-minus"
                  label="Contraer Todo"
                  @click="collapseAll"
                  class="mr-2"
                />
              </span>
              <span
                class="block mt-2 md:mt-0 p-input-icon-left"
                v-if="'Comisiones Listar' in auth.user.permissions"
              >
                <Button
                  text
                  icon="pi pi-plus"
                  label="Expandir Todo"
                  @click="expandAll"
                  class="mr-2"
                />
              </span>
            </div>
          </template>
          <template #loading>
            <div class="flex align-items-center justify-content-center">
              <ProgressSpinner />
            </div>
          </template>
          <template #empty>
            <span class="p-invalid">
              Debe aplicar filtros para ver resultados en la tabla!</span
            >
          </template>
          <Column
            expander
            v-if="'Comisiones Listar' in auth.user.permissions"
          ></Column>
          <Column field="fecha" header="FECHA"></Column>
          <Column field="grupo" header="GRUPO"></Column>
          <Column
            field="detalle"
            header="DETALLE"
            style="font-weight: bold"
          ></Column>
          <Column field="comision_para" header="COMISIÓN PARA"></Column>
          <Column
            field="ingreso"
            header="INGRESO"
            style="text-align: right; font-weight: bold; font-size: 14px"
          >
            <template #body="{ data }">
              {{ convertirNumeroGermanicFormat(data.ingreso) }}
            </template>
          </Column>
          <Column
            field="egreso"
            header="EGRESO"
            style="text-align: right; font-weight: bold; font-size: 14px"
          >
            <template #body="{ data }">
              {{ convertirNumeroGermanicFormat(data.egreso) }}
            </template>
          </Column>
          <Column
            field="saldo"
            header="SALDO"
            style="text-align: right; font-weight: bold; font-size: 14px"
          >
            <template #body="{ data }">
              {{ convertirNumeroGermanicFormat(data.saldo) }}
            </template>
          </Column>
          <Column field="estado" header="ESTADO">
            <template #body="{ data }">
              <Badge
                :value="
                  data.estado == 0
                    ? 'PENDIENTE'
                    : data.estado == 1
                    ? 'APROBADO'
                    : data.estado == 2
                    ? 'RECHAZADO'
                    : data.estado == 3
                    ? 'PAGADO'
                    : 'N/A'
                "
                :severity="
                  data.estado == 0
                    ? 'warning'
                    : data.estado == 1
                    ? 'success'
                    : data.estado == 2
                    ? 'danger'
                    : data.estado == 3
                    ? 'info'
                    : 'N/A'
                "
              />
            </template>
          </Column>
          <Column header="ACCIONES">
            <template #body="slotProps">
              <div class="flex justify-content-center">
                <Button
                  v-show="false"
                  class="p-button-rounded p-button-danger p-button-sm mr-1"
                  v-tooltip.top="'Rechazar Comisión'"
                  icon="pi pi-times"
                  @click="rechazarComision(slotProps.data.id)"
                  :disabled="
                    slotProps.data.estado == 2 ||
                    slotProps.data.venta_id == null
                  "
                />
                <Button
                  v-show="false"
                  class="p-button-rounded p-button-blue p-button-sm mr-1"
                  icon="pi pi-check"
                  :disabled="
                    slotProps.data.estado != 1 ||
                    slotProps.data.venta_id == null
                  "
                  v-tooltip.top="'Marcar como Pagado, no se registrará en caja'"
                  @click="aprobarComision(slotProps.data.id)"
                />
                <Button
                  v-if="slotProps.data.venta_id != null"
                  class="p-button-rounded p-button-danger p-button-sm mr-2"
                  v-tooltip.top="'Imprimir Venta'"
                  icon="pi pi-file-pdf"
                  @click="imprimirVenta(slotProps.data.venta_id)"
                />
                <Button
                  v-if="slotProps.data.venta_id == null"
                  class="p-button-rounded p-button-warning p-button-sm"
                  v-tooltip.top="'Imprimir Detalle Pago'"
                  icon="pi pi-file-pdf"
                  @click="imprimirDetallePagoComision(slotProps.data.id)"
                />
              </div>
            </template>
          </Column>
          <template
            #expansion="slotProps"
            v-if="'Comisiones Listar' in auth.user.permissions"
          >
            <div style="background-color: #ffffff">
              <h5>
                <span style="border-bottom: 1px solid #ccc">
                  DETALLE DE VENTA:
                  <strong
                    >{{ slotProps.data.id }} -
                    {{ slotProps.data.razon_social }}</strong
                  >
                </span>
              </h5>
              <DataTable
                :value="slotProps.data.productos"
                class="p-datatable-sm"
                responsiveLayout="scroll"
                stripedRows
                showGridlines
                selectionMode="single click"
                style="
                  border: 1px solid #000000;
                  border-radius: 2px;
                  padding: 2px;
                  box-shadow: 0px 0px 1px 0px #000000;
                  font-size: 12px;
                "
              >
                <Column
                  field="id"
                  header="COD_PROD"
                  class="text-right"
                ></Column>
                <Column
                  field="descripcion"
                  header="PRODUCTO/DESCRIPCIÓN"
                  style="width: 300px; font-weight: bold"
                ></Column>
                <Column header="CANTIDAD" class="text-right">
                  <template #body="slotProps">
                    {{ slotProps.data.cantidad }}
                  </template>
                </Column>
                <Column header="PRECIO" class="text-right">
                  <template #body="slotProps">
                    {{ slotProps.data.precio }}
                  </template>
                </Column>
                <Column header="SUBTOTAL" class="text-right">
                  <template #body="slotProps">
                    {{ slotProps.data.cantidad * slotProps.data.precio }}
                  </template>
                </Column>
                <Column header="DESCUENTO" class="text-right">
                  <template #body="slotProps">
                    {{ slotProps.data.descuento_neto }}
                  </template>
                </Column>
                <Column header="TOTAL" class="text-right">
                  <template #body="slotProps">
                    {{
                      slotProps.data.cantidad * slotProps.data.precio -
                      slotProps.data.descuento_neto
                    }}
                  </template>
                </Column>
              </DataTable>
            </div>
          </template>
        </DataTable>
        <Dialog
          v-model:visible="motivoRechazoDialog"
          modal
          header="MOTIVO RECHAZA DE PAGO"
          :style="{ width: '400px' }"
        >
          <div class="p-fluid">
            <div class="p-field">
              <label for="observacion"><strong>DESCRIPCIÓN: </strong></label>
              <Textarea
                v-model="observacion"
                id="observacion"
                :autoResize="true"
                :rows="1"
              />
            </div>
          </div>
          <div class="flex justify-content-end mt-4">
            <Button
              label="CANCELAR"
              icon="pi pi-times"
              class="p-button-success mr-2 p-button-lg"
              @click="motivoRechazoDialog = false"
            />
            <Button
              label="RECHAZAR PAGO"
              icon="pi pi-trash"
              class="p-button-danger p-button-lg"
              :loading="enviando"
              @click="rechazarPago(rechazarPagoId)"
            />
          </div>
        </Dialog>
      </div>
      <ComisionesCreate
        :show="newPagoComisionDialog"
        :pagos_comisiones="pagos_comisiones"
        @closeModal="ocultarDialog"
        @actualizarListado="cargarComisiones"
      />
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import { useAuth } from "@/stores";
import ComisionService from "@/service/ComisionService";
import ComisionesCreate from "@/module/comisiones/ComisionesCreate.vue";

export default {
  name: "ComisionesList",
  components: {
    ComisionesCreate,
  },
  data() {
    return {
      fecha_inicio: new Date(),
      fecha_fin: null,
      enviando: false,
      comisiones: [],
      sucursalSelected: 0,
      sucursales: [{ id: 0, nombre: "TODAS" }],
      usuarioSelected: 0,
      usuarios: [],
      buscar: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
      estadoSelected: 4,
      estados: [
        { id: 4, nombre: "TODOS" },
        { id: 0, nombre: "PENDIENTE" },
        { id: 1, nombre: "APROBADO" },
        { id: 2, nombre: "RECHAZADO" },
        { id: 3, nombre: "PAGADO" },
      ],
      codigoVenta: null,
      expandedRows: [],
      motivoRechazoDialog: false,
      observacion: null,
      rechazarPagoId: null,
      newPagoComisionDialog: false,
      total_a_cobrar_comision: 0,
      pagos_comisiones: {},
    };
  },
  auth: null,
  comisionService: null,
  created() {
    this.auth = useAuth();
    this.comisionService = new ComisionService();
    this.cargarComisiones();
    this.cargarDatosNecesarios();
  },
  mounted() {
    this.cargarDatosNecesarios();
  },
  methods: {
    imprimirDetallePagoComision(id) {
      this.comisionService
        .imprimirDetallePagoComision(id)
        .then(() => {})
        .catch((error) => console.log(error));
    },
    newPagoComision() {
      if (this.usuarioSelected != 0) {
        this.pagos_comisiones = {
          total_a_cobrar_comision: this.total_a_cobrar_comision,
          nombre_usuario: this.usuarios.find(
            (x) => x.id == this.usuarioSelected
          ).name,
        };
      }
      this.newPagoComisionDialog = true;
    },
    ocultarDialog() {
      this.newPagoComisionDialog = false;
    },
    rechazarComision(id) {
      this.rechazarPagoId = id;
      this.motivoRechazoDialog = true;
    },
    rechazarPago(id) {
      this.enviando = true;
      let datos = {
        id: id,
        observacion: this.observacion,
      };
      this.comisionService
        .rechazarPago(datos)
        .then((response) => {
          this.enviando = false;
          this.motivoRechazoDialog = false;
          this.$toast.add({
            severity: "success",
            summary: "Éxito",
            detail: response.mensaje,
            life: 3000,
          });
          this.cargarComisiones();
          this.observacion = null;
        })
        .catch((error) => {
          this.enviando = false;
          this.motivoRechazoDialog = false;
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: error.response.data.mensaje,
            life: 3000,
          });
        });
    },
    aprobarComision(id) {
      this.enviando = true;
      let datos = {
        id: id,
      };
      this.comisionService
        .aprobarPago(datos)
        .then((response) => {
          this.enviando = false;
          this.$toast.add({
            severity: "success",
            summary: "Éxito",
            detail: response.message,
            life: 3000,
          });
          this.cargarComisiones();
        })
        .catch((error) => {
          this.enviando = false;
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: error.response.data.message,
            life: 3000,
          });
        });
    },
    cargarDatosNecesarios() {
      this.comisionService.cargarDatosNecesarios().then((response) => {
        this.sucursales = response.datos.sucursales ?? [];
        this.usuarios = response.datos.usuarios ?? [];
        this.usuarios.unshift({ id: 0, name: "TODOS" });
      });
    },
    imprimirVenta(venta_id) {
      this.comisionService
        .imprimirVenta(venta_id)
        .then(() => {})
        .catch((error) => console.log(error));
    },
    BuscarFiltro() {
      this.comisiones = [];
      this.enviando = true;
      this.cargarComisiones();
    },
    cargarComisiones() {
      let datos = {
        sucursal: this.sucursalSelected,
        fecha_inicio: this.fecha_inicio,
        fecha_fin: this.fecha_fin,
        estado: this.estadoSelected,
        codigoVenta: this.codigoVenta,
        nombre_usuario:
          this.usuarioSelected == 0
            ? null
            : this.usuarios.find((x) => x.id == this.usuarioSelected).name,
      };
      this.comisionService.filtrarComisiones(datos).then((response) => {
        this.comisiones = response.comisiones ?? [];
        this.total_a_cobrar_comision = response.total_a_cobrar_comision;
        this.enviando = false;
      });
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(numero);
    },
    expandAll() {
      this.expandedRows = this.comisiones.filter((p) => p.id);
    },
    collapseAll() {
      this.expandedRows = null;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/demo/badges.scss";

.p-invalid {
  color: red;
}

.p-fondo {
  background-color: #7bffae;
  border-radius: 5px;
  border: 1px solid black;
  box-shadow: 0px 0px 1px 0px #000000;
  border-bottom: 3px solid #000000;
}
</style>
